import { FC } from 'react';
import { Image } from 'components/01-atoms/Image';
import { VideoPlayer, VideoPlayerProps } from 'components/01-atoms/VideoPlayer';

export type HeroBodyBaseProps = {
  /** Source for the background image */
  imageSrc: string;
  /** Alt for the background image. */
  imageAlt: string;
  /** Options to be passed to the VideoPlayer */
  videoConfig?: VideoPlayerProps;
  /** Boolean to show gradient overlay */
  showOverlay?: boolean;
};

export const HeroBodyBase: FC<HeroBodyBaseProps> = ({
  imageSrc,
  imageAlt,
  children,
  videoConfig,
  showOverlay = false
}) => (
  <div className="relative">
    {imageSrc && !videoConfig && (
      <Image
        src={imageSrc}
        alt={imageAlt}
        shouldLazyLoad={false}
        classes="object-cover max-h-[220px] narrow:max-h-[576px] narrow-x:max-h-full"
      />
    )}
    {videoConfig && (
      <VideoPlayer
        {...videoConfig}
        classes={`object-cover max-h-fit ${videoConfig.classes || ''}`}
      />
    )}
    {showOverlay && (
      <span className="u-gradient--overlay absolute bottom-0 left-0 block h-full w-full" />
    )}
    <div className="relative mb-10 bg-black px-10 pb-5 pt-10 narrow-x:absolute narrow-x:bottom-0 narrow-x:bg-transparent ">
      <div className="container mx-auto">{children}</div>
    </div>
  </div>
);
