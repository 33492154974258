import { FC } from 'react';
import { Button } from 'components/01-atoms/Button';
import { H2, H5 } from 'components/01-atoms/Heading';
import { LinkConfig } from 'types/WebMicrofrontends';
import { VideoPlayerProps } from 'components/01-atoms/VideoPlayer';

import { HeroBodyBase } from './HeroBodyBase';

export type HeroBodyProps = {
  /** Source for the background image */
  imageSrc: string;
  /** Alt for the background image. */
  imageAlt: string;
  /** Title for hero body copy --can be an element for logos, ect. */
  title: JSX.Element | string;
  /** Text for hero body copy */
  description: string;
  /** Config object for cta button */
  button?: LinkConfig | null;
  /** Disclaimer text to go below button */
  disclaimer?: string;
  /** Options to be passed to the VideoPlayer */
  videoConfig?: VideoPlayerProps;
  /** Boolean to show gradient overlay */
  showOverlay?: boolean;
};

export const HeroBody: FC<HeroBodyProps> = ({
  imageSrc,
  imageAlt,
  title,
  description,
  disclaimer = '',
  button = null,
  videoConfig,
  showOverlay = false
}) => (
  <HeroBodyBase
    imageSrc={imageSrc}
    imageAlt={imageAlt}
    videoConfig={videoConfig}
    showOverlay={showOverlay}
  >
    <div className="max-w-xl text-left text-network-white">
      <div>
        <H2
          headingText={title}
          classes="text-3xl font-medium tracking-wide-x"
        />
        <div className="py-6 leading-7">{description}</div>
      </div>
      {button && (
        <Button
          type="button"
          theme="primary"
          size="sm"
          classes={button.classes}
          text={button.text || ''}
          href={button.href || ''}
          target={button.target}
          onClick={button.onClick}
        />
      )}
      {disclaimer && (
        <H5
          headingText={disclaimer}
          classes="tracking-wide-x font-200 text-xs mt-2"
        />
      )}
    </div>
  </HeroBodyBase>
);
